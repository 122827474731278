import ParticipantManager from "../../libs/ParticipantManager/ParticipantManager";
import ProktorAI from "../../libs/ProktorAI";
import { Err } from "../../libs/logger";
import { fetchParticipantsByProctorId, fetchParticipantsBySearch } from "../../libs/proctor_utils";

export const __ParticipantManager__ = function (_this) {
  _this.pm = null;

  this.pmCallback = () => {
    const par = _this.pm.getParticipants();
    const stateEmotion = _this.stateEmotion;
    for (let i in par) {
      if (par[i].status === "online") {
        if (stateEmotion[par[i].id] === undefined) {
          stateEmotion[par[i].id] = ProktorAI.warningAnalytic({ start: Date.now(), data: [0, 0, 0, 0, 0] })
        }
      }
    }
    _this.setState({
      // stateEmotion: stateEmotion,
      participants: { ...par },
    })
    _this.stateEmotion = stateEmotion;
  }

  this.deleteAllParticipants = () => {
    _this.pm.deleteAllParticipants();
  }

  this.addParticipant = (d, stateEmotion) => {
    for (const element of d) {
      _this.pm.addParticipant(
        element.id,
        element.uuid,
        element.name,
        element.ice_id,
        element.photo,
        element.folder_rand,
        element.status === undefined ? "offline" : element.status,
        element.validation_status,
        {
          green: element.green,
          yellow: element.yellow,
          orange: element.orange,
          pink: element.pink,
          red: element.red,
          value: element.value,
          always_valid: element.always_valid,
          confirmed: element.confirmed
        });

      if (element.status === "online") {
        if (stateEmotion[element.id] === undefined) {
          stateEmotion[element.id] = ProktorAI.warningAnalytic({ start: Date.now(), data: [0, 0, 0, 0, 0] })
        }
      }
    }
  }

  this.fetchParticipantToState = (searchText = null, cb = null) => {
    const handleDataResult = (data) => {
      if (data === null) {
        Err("[err-63] failed to fetch data.");
        return;
      }
      if (data.status === "success") {
        let d = data.result;
        if (d === null) {
          if (cb) { cb() };
          return;
        }
        const stateEmotion = _this.stateEmotion === undefined ? {} : _this.stateEmotion;

        this.addParticipant(d, stateEmotion)

        _this.setState({
          participantNumber: d.length,
          participants: _this.pm.getParticipants(),
          fetchParticipantsStatus: true,
          participantManagerStatus: true,
          // stateEmotion: stateEmotion
        }, () => {
          if (cb) { cb() };
        });
        _this.stateEmotion = stateEmotion;
      } else {
        if (cb) { cb() };
      }
    }
    const handleError = (err) => {
      Err(err);
    }

    fetchParticipantsBySearch(searchText, handleError)
      .then(handleDataResult)
  }

  this.fetchParticipantsData = (searchText = null, cb = null) => {
    const handleDataResult = (data) => {
      if (data === null) {
        Err("[err-63] failed to fetch data.");
        return;
      }
      if (data.status === "success") {
        // delete all participants first
        this.deleteAllParticipants();

        let d = data.result;
        if (d === null) {
          _this.setState({
            participantNumber: 0,
            participants: _this.pm.getParticipants(),
            fetchParticipantsStatus: true,
            participantManagerStatus: true
          });
          if (cb) { cb() };
          return;
        }
        const stateEmotion = _this.stateEmotion === undefined ? {} : _this.stateEmotion;

        this.addParticipant(d, stateEmotion)

        _this.setState({
          participantNumber: d.length,
          participants: _this.pm.getParticipants(),
          fetchParticipantsStatus: true,
          participantManagerStatus: true,
          // stateEmotion: stateEmotion
        });
        _this.stateEmotion = stateEmotion;
        if (cb) { cb() };
      } else {
        if (cb) { cb() };
      }
    }

    const handleError = (err) => {
      Err(err);
    }
    if (searchText) {
      fetchParticipantsBySearch(searchText, handleError)
        .then(handleDataResult)
    } else {
      fetchParticipantsByProctorId(_this.context.profile.id, handleError)
        .then(handleDataResult);
    }
  }

  this.initParticipantManager = () => {
    _this.pm = new ParticipantManager(_this.context.profile.id, "proctorName",
      // _this.commonWs,
      this.pmCallback
    );

    this.fetchParticipantsData();
  }
}